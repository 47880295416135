import "lazysizes";
import HorizontalScroll from "./horizontalscroll.js";
import "./assets/js/jquery.FloatLabel.js";

var body = document.querySelector("body");
export function toggleMenu(){
	body.classList.toggle("menu-open");
}
[...document.querySelectorAll(".slide-menu a[href*='#']")].forEach(function(link){
	link.addEventListener("click", function(e){
		body.classList.remove("menu-open");
		if (location.pathname.replace(/^\//,"") == this.pathname.replace(/^\//,"") && location.hostname == this.hostname) {
			e.preventDefault();
			var id = this.hash.substring(1);
			goToId(id);
		}
	});
});

var menu = document.getElementById("menu-button");
if (menu) menu.addEventListener("click", toggleMenu);
var slide_heading = document.getElementById("menu-title");
if (slide_heading) slide_heading.addEventListener("click", toggleMenu);

$(function(){
	$("form").addClass("floatlabel-active").find(".js-float-label-wrapper").FloatLabel();
	// smooth scroll to anchor links
	const anchors = document.querySelectorAll("a[href^='#']");
	for (var i=0; i<anchors.length; i++){
		var link = anchors[i];
		if (link)
			link.addEventListener("click", anchorLinks);
	}
	// slide-show
	if (document.getElementsByClassName("value").length){
		startSVG();
		valueSlideShow();
	}
	/* expanding panes */
	var expand_panes = document.getElementsByClassName("expand-click");
	if (expand_panes){
		[...expand_panes].forEach(function(click_pane){
			click_pane.addEventListener("click", function(){
				togglePane(click_pane);
			});
		});
	}
	var expander_close = document.getElementsByClassName("expander-close");
	if (expander_close){
		[...expander_close].forEach((trigger) =>{
			trigger.addEventListener("click", function(e){
				var me = e.target.closest("div.expanding");
				// get elements with my data-id + get id & deactivate them all
				var data_id = me.getAttribute("data-id");
				var data_ids = document.querySelectorAll("div[data-id='"+data_id+"']");
				data_ids.forEach(function(split_pane){
					split_pane.classList.remove("active");
					mobilePane(split_pane);
				});
				var click_pane = document.getElementById(data_id);
				click_pane.classList.remove("active");
				// scroll to the click-pane
				scrollToTarget(click_pane);
			});
		});
	}
	// team - close columns
	var close_profiles = document.getElementsByClassName("profile-close");
	if (close_profiles){
		[...close_profiles].forEach((trigger) => {
			trigger.addEventListener("click", function(event){
				var bio_content = event.target.closest("div.fade-in");
				var bio_id = bio_content.getAttribute("data-id");
				var placeholder = bio_content.closest("js-pane");
				var this_profile = getActiveProfile(placeholder);
				deactivateProfile(this_profile, bio_id, placeholder);
			});
		});
	}
	manageProfileClicks();
});
var splits = document.querySelectorAll(".split-content-pane");
if (splits) {
	import(/* webpackChunkName: "contentsplit" */ "./contentsplit.js").then(module => {
		splits.forEach( elem => new module.ContentSplit(elem));
	});
}

//activate anchor sections on load
document.addEventListener("DOMContentLoaded", function(){
	if (document.location.hash){
		var the_id = document.location.hash.substring(1);
		goToId(the_id);
	}
	var home_logo = document.getElementById("sdg-home");
	if (home_logo){
		home_logo.classList.add("active");
	}
	// we want svg size, so trigger when loaded.
	checkValues();
	// community page has images - so calculate after load
	setSplitHeights();
});
window.addEventListener("load",function(){
	setSplitHeights();
});

export function anchorLinks(e){
	e.preventDefault();
	var my_id = this.hash.substring(1);
	goToId(my_id);
}
export function isTargetExpander(target){
	return target.classList.contains("expand-click");
}
export function isTargetLastExpander(target){
	var isLastExpander = false;
	if (isTargetExpander(target)) {
		var expanders = Array.from(target.parentElement.querySelectorAll(".expand-click"));
		isLastExpander = target == expanders[expanders.length-1];
	}
	return isLastExpander;
}
export function scrollToTarget(target, callback)  {
	if (mode == "mobile") {
		scrollToTargetMobile(target, callback);
	} else {
		scrollToTargetDesktop(target, callback);
	}            
}
export function scrollToTargetMobile(target,callback) {
	// On mobile we disable the horizontal scroll system, so we rely on the browser built in to scroll
	target.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
	// wait for scroll then run callback
	if (callback)
		setTimeout(callback, 900);
}
export function scrollToTargetDesktop(target, callback){
	// On desktop we need to ask the horizontal scroll system to scroll, or it will get out of sync with the scroll offset.    
	window.horizontalscroll.scrollTo(target,callback);
}
export function goToId(id){
	var target_el = document.getElementById(id);
	if (target_el){
		if (target_el.offsetWidth){ // if it is displayed (currently visible)
            
			scrollToTarget(target_el,()=>{toggleExpands(target_el);});
		} else {
			toggleExpands(target_el);
		}
	}
}
export function toggleExpands(el){
	if (el.classList.contains("expand-click")){
		// expanding panes
		togglePane(el);
	} else if (el.classList.contains("bio")){
		//staff
		var my_profile = el.closest("div.profile");
		var bio_id = el.getAttribute("id");
		var placeholder = getNextPlaceholder(my_profile);
		//scrollIntoView done in below
		toggleProfile(my_profile, bio_id, placeholder);
	}
}
export function togglePane(click_pane){
	click_pane.classList.toggle("active");
	// get elements with matching data-id
	var id = click_pane.getAttribute("id");
	// split-content so 1+
	var data_ids = document.querySelectorAll("div[data-id='"+id+"']");
	data_ids.forEach(function(split_pane){
		// toggle them
		split_pane.classList.toggle("active");
		// could do: rm 'on-top' class if active + listen to transition end to set it
		mobilePane(split_pane);
	});
	if (click_pane.classList.contains("active")) {
		// We want to align this pane to the top or left of the viewport, so the user can see the expanded pane 
		// below or to the right.
		scrollToTarget(click_pane);

		/* if last element in main */
		// on last element the position we want to scroll to does not exist until the element expands.
		// so we keep scrolling as it appears as a workaround
		if (isTargetLastExpander(click_pane)) {
			keepScrolling(click_pane);
		}
	}
}
export function keepScrolling(elem){
	for(var i=0; i < 10; i++){
		setTimeout(function(){
			scrollToTarget(elem);
		}, i*100);
	}
}

export function setSplitHeights(){
	if (mode == "mobile"){
		var click_div = document.querySelector(".half-pane.expand-click");
		var split_divs = [...document.getElementsByClassName("split-content-pane")];
		var padding = 60; //Sensible(?) default
		if (click_div) {
			var style = window.getComputedStyle(click_div);
			padding = parseInt(style.paddingTop)+parseInt(style.paddingBottom);
		}

		split_divs.forEach(function(split){
			// inactive splits have maxHeight 0px and no padding
			var new_max = split.scrollHeight + padding + 50;// add some extra
			split.setAttribute("data-H", new_max + "px");
		});

		// if any active - reset height (for resize)
		[...document.querySelectorAll(".expanding.active")].forEach(function(pane){
			pane.style.maxHeight = pane.getAttribute("data-H");
		});
	}
}
export function mobilePane(the_div){
	if (!the_div) return;
	// profile bios are expanded on mobile - so this is only split-content expanding panes
	if (mode == "mobile"){
		if (the_div.classList.contains("active")) {
			//data attr set on load + resize
			setSplitHeights();
			//the_div.style.maxHeight = the_div.getAttribute("data-H");
		} else {
			the_div.style.maxHeight = "0px";
		}
	}
}

/* horizontal and vertical modes */
window.horizontalscroll = null;
var mode = null;
var shapes = false;
const mobileMQL = (window.matchMedia) ? window.matchMedia("(max-width:960px), (max-height:700px)") : null;
export function mobileChange(mql) {
	if (mql && mql.matches) {
		activateMobileMode();
	} else {
		activateDesktopMode();
	}
}
mobileChange(mobileMQL);
if (mobileMQL) {
	if ("addEventListener" in mobileMQL)
		mobileMQL.addEventListener("change", mobileChange);
	else
		mobileMQL.addListener(mobileChange);
}

export function activateDesktopMode(){
	if (mode == "desktop") return;
	mode = "desktop";
	document.documentElement.scrollTop = 0;
	if (window) window.horizontalscroll = new HorizontalScroll({container:document.querySelector("main")});
	if (!shapes){
		scroll_shapes();
		let main = document.querySelector("main");
		if (main) {
			main.addEventListener("scroll", scroll_shapes);
			main.addEventListener("mousemove", mouse_shapes);
		}
	}
}
export function activateMobileMode(){
	if (mode == "mobile") return;
	mode = "mobile";
	if (window.horizontalscroll)
		window.horizontalscroll.destroy();
	window.horizontalscroll = null;
	if (shapes){
		document.querySelector("main").removeEventListener("scroll", scroll_shapes);
		document.querySelector("main").removeEventListener("mousemove", mouse_shapes);
		shapes = false;
	}
}

export function setupMenuExpand(){
	let menus = Array.from(document.querySelectorAll(".slide-menu li"));
	menus.forEach(menu => {        
		if (menu.querySelectorAll("ul").length > 0) {
			var expander = document.createElement("span"); 
			expander.classList.add("expand");
			expander.innerHTML= "<i class=\"nav-arrow\"></i>";
			menu.appendChild(expander);
			expander.addEventListener("click", function(e) {
				e.stopPropagation();
				menu.classList.toggle("expand-active");
			});
		}
	});
}
setupMenuExpand();


/* slideshow functions */
export function startSVG(){
	var active_nav = document.querySelector(".values.scroll-nav .nav-item.active");
	var letter = active_nav.dataset.letter;
	var active_cat = document.querySelector(".values.scroll-nav .name.active");
	var mode = active_cat.getAttribute("id");
	// set active on svg
	var the_svg = document.getElementById("values-letters");
	the_svg.classList.add("letter-"+letter, mode);
	the_svg.setAttribute("data-letter", letter);
}
export function checkValues(){
	var wrapper = document.querySelector(".values .scroller");
	if (wrapper){
		var values = document.querySelectorAll(".scroller .value");
		var v_svg = document.getElementById("values-letters").getBoundingClientRect();
		var padH = (v_svg)? v_svg.height : wrapper.offsetHeight;
		var maxH = 0;
		if (mobileMQL && mobileMQL.matches){ // set height + padding
			wrapper.style.removeProperty("height");
			values.forEach(value => {
				value.style.removeProperty("padding-top");
				value.style.paddingTop = padH +"px";
				var myH = value.offsetHeight;
				if (myH > maxH){
					maxH = myH;
				}
			});
			wrapper.style.height = padH + maxH +"px";
		} else { // clear height + padding
			wrapper.style.removeProperty("height");
			values.forEach(value => {
				value.style.removeProperty("padding-top");
			});
		}
	}
}
export function valueSlideShow(){
	var swapInt = 4000;
	var values = document.querySelectorAll(".scroller .value");
	var nav = document.querySelectorAll(".values.scroll-nav .nav-item");
	var interval = null;
	if (values && values.length > 1){
		var active_selector = ".scroller .value.active";
		interval = setInterval(function(){ swapSlide(values, active_selector, nav); }, swapInt);
	}
	nav.forEach((item)=>{
		item.addEventListener("click", function(){
			clearInterval(interval);
			var id = this.getAttribute("data-id");
			var new_slide = document.getElementById(id);
			if (new_slide){
				value_nav(new_slide, nav);
				clearOthers("value", new_slide);
				new_slide.classList.add("active");
			}
			interval = setInterval(function(){ swapSlide(values, active_selector, nav); }, swapInt);
		});
	});
}
export function value_nav(active_slide, nav_items){
	var active_id = active_slide.getAttribute("id");
	var cat_name = document.querySelectorAll(".scroll-nav h4");
	var the_svg = document.getElementById("values-letters");
	var old_mode = the_svg.dataset.mode;
	var old_letter = the_svg.dataset.letter;
	if (cat_name){
		// deactivate all headings
		cat_name.forEach((h)=>{
			h.classList.remove("active");
		});
	}
	nav_items.forEach((item) =>{
		var data_id = item.getAttribute("data-id");
		if (data_id == active_id){
			/* letter */
			item.classList.add("active");
			var new_letter = item.getAttribute("data-letter");
			/* category */
			var my_cat = item.closest(".category").querySelector("h4");
			my_cat.classList.add("active");
			var new_mode = my_cat.getAttribute("id");
			/* svg */
			/* update data attributes */
			the_svg.dataset.mode = new_mode;
			the_svg.dataset.letter = new_letter;
			/* update classes */
			the_svg.classList.remove(old_mode, "letter-"+old_letter);
			the_svg.classList.add(new_mode, "letter-"+new_letter);
		} else {
			item.classList.remove("active");
		}
	});
}
export function swapSlide(list, slide, nav_items){
	var current = document.querySelectorAll(slide);
	var next = current[0].nextElementSibling;
	var new_el = "";
	current[0].classList.remove("active");
	if (next){
		new_el = next;
	} else {
		new_el = list[0];
	}
	new_el.classList.add("active");
	if (new_el.classList.contains("value")){
		value_nav(new_el, nav_items);
	}
}


/* staff expands */
export function toggleProfile(this_profile, bio_id, placeholder){
	//deactivation needs to happen in a different order
	if (this_profile.classList.contains("active")){
		deactivateProfile(this_profile, placeholder);
	} else {
		activateProfile(this_profile, bio_id, placeholder);
	}
}
function fade_in(new_content, bio_container){
	new_content.classList.add("fade-in");
	bio_container.classList.add("active","half-pane");
}
export function activateProfile(person, id, bio_container){
	var tiles_wrap = person.parentElement;
	if (person){
		clearOthers("profile");
		person.classList.add("active");

		if (mode == "desktop"){
			// we need the offset left of the main, so use parent
			scrollToTarget(tiles_wrap);
		} else {
			scrollToTarget(person);
		}
	}
	if (id){ // get content
		// inner div used to fade between profiles in this column
		var bio_content = document.createElement("div");
		bio_content.setAttribute("data-id", id);
		bio_content.innerHTML = document.getElementById(id).innerHTML;
		if (bio_container) {
			clearOthers("js-pane", bio_container);
			bio_container.append(bio_content);
			var new_content = bio_container.lastChild;
			var old_content = bio_container.firstChild;
			if (old_content != new_content){
				old_content.classList.remove("fade-in");
				// wait for half of transition duration
				setTimeout(function(){
					bio_container.removeChild(old_content);
					fade_in(new_content, bio_container);
				}, 250);
			} else fade_in(new_content, bio_container);
			
			// placeholder close
			var profile_close = new_content.getElementsByClassName("profile-close");
			if (profile_close){
				profile_close[0].addEventListener("click", function(){
					deactivateProfile(person, bio_container);
				});
			}
		}
	}

	if (mode == "desktop"){
		// the area we need to scroll to doesn't exist until the element expands
		// so we keep scrolling as it appears as a workaround
		keepScrolling(tiles_wrap);
	} else {
		scrollToTarget(person);
	}
}
export function clearOthers(class_str, el){
	var class_elements = [...document.getElementsByClassName(class_str)];
	if (class_elements){
		class_elements.forEach((div) =>{
			if (!el || div != el){
				div.classList.remove("active");
			}
		});
	}
}
var getNextPlaceholder = function(el){
	var next_el = el.nextElementSibling;
	while(next_el){
		// make sure element is displayed - display none takes up no space
		if (next_el.classList.contains("js-pane") && next_el.offsetWidth != 0){
			return next_el;
		}
		next_el = next_el.nextElementSibling;
	}
};
export function deactivateProfile(person, bio_container){
	if (bio_container){
		var bio_classes = bio_container.classList;
		bio_classes.remove("active");
		// wait for transition
		setTimeout(function(){
			while (bio_container.firstChild) bio_container.removeChild(bio_container.firstChild);
			bio_classes.remove("half-pane");
		}, 500);
	}
	if (person) person.classList.remove("active");
}
export function getActiveProfile(el){
	var pre_el = el.previousElementSibling;
	while(pre_el){
		if (pre_el.classList.contains("active")){
			return pre_el;
		}
		pre_el = pre_el.nextElementSibling;
	}
}

/* enable + disable profile clicks */
export function manageProfileClicks(){
	var staff_expands = null;
	const staffTilesMQL = (window.matchMedia) ? window.matchMedia("(max-width:500px)") : null;
	function staffMode(mql){
		if (mql && mql.matches){
			deactivateStaffExpands();
		} else {
			enableStaffExpands();
		}
	}
	staffMode(staffTilesMQL);
	if (staffTilesMQL) {
		if ("addEventListener" in staffTilesMQL)
			staffTilesMQL.addEventListener("change", staffMode);
		else
			staffTilesMQL.addListener(staffMode);
	}

	function deactivateStaffExpands(){
		if (staff_expands == "off") return;
		staff_expands = "off";
		// disable clicks
		var profiles = [...document.getElementsByClassName("profile")];
		if (profiles){
			profiles.forEach((profile) => {
				profile.querySelector("a").removeEventListener("click", anchorLinks);
			});
		}
		[...document.querySelectorAll(".js-pane")].forEach((pane)=>{
			pane.classList.remove("active");
			pane.innerHTML="";
		});
	}
	function enableStaffExpands(){
		if (staff_expands == "on") return;
		// check if they've been disabled
		if (staff_expands == "off") {
			// disable clicks
			var profiles = [...document.getElementsByClassName("profile")];
			if (profiles){
				profiles.forEach((profile) => {
					if (profile.querySelector("a")) profile.querySelector("a").addEventListener("click", anchorLinks);
				});
			}
		}
		staff_expands = "on";
	}
}
/** flare animations **/
export function mouse_shapes(e){
	var x = e.pageX * 0.005;
	var y = e.pageY * 0.005;

	[...document.getElementsByClassName("flare")].forEach(function(screen){
		screen.style.transform = "translate("+x+"px,"+y+"px)";
	});
}
export function size_flare_wrap(){
	var wrap = document.querySelector(".flare-wrap");
	if (!wrap) return;
	wrap.style.display = "none"; // Prevent wrap from affecting calculation.    
	//TODO: The browser does not seem to always be recalculating the overall width here. 
	// We might need to force a reflow/relayout to get the updated main element width.
	let main_width = wrap.parentElement.scrollWidth;
	wrap.style.width = main_width+"px";
	wrap.style.display = "block";
}
var resizeTimer;
window.addEventListener("resize", function(){
	clearTimeout(resizeTimer);
	resizeTimer = setTimeout(function(){
		size_flare_wrap();
		checkValues();
		setSplitHeights();
	}, 100);
});
size_flare_wrap();
window.size_flare_wrap = size_flare_wrap;
export function scroll_shapes(){
	var view_width = window.innerWidth;

	[...document.getElementsByClassName("scroll-shape")].forEach(function(shape){
		var shape_bounds = shape.getBoundingClientRect();
		var speed = (shape.classList.contains("more"))? 2 : 1;
		if (view_width > shape_bounds.left){
			shape.classList.add("active"); //help with debugging
			var scroll_percent = ((view_width - shape_bounds.left) / view_width) * 100;
			var scroll_distance = scroll_percent * speed;
			shape.style.transform = "translateX("+scroll_distance+"px)";
		} else {
			shape.classList.remove("active");
		}
	});
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	if (container.matches("#map")){        
		const url = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCZrVexc45PZ10gw9Cmk9JkcxVOFg0wpYM&callback=initMap";
		const script = document.createElement("script");

		script.setAttribute("src", url);
		//script.setAttribute('async', '');
		//script.setAttribute('defer', '');

		document.head.appendChild(script);
		return;
	}
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});