import { EventEmitter } from "events";
import { SpringSystem } from "rebound";
const DRAG_DELTA = 10;
const VIEWPORT_MATCH_WIDTH = 100;
export default class HorizontalScroll extends EventEmitter {
	/**
     * Initialize a new horizontal scroll instance.
     * Will immediately bind to container.
     *
     */
	constructor({ container = document.documentElement, showScrollbars = false, preventVerticalScroll = false } = {}) {
		super();		
		if (!container) return;
		this.observer = null;
		this.containerIsIntersecting = false;
		this.style = null;
		this.cssClass = "__horizontal-container-" + Math.round(Math.random() * 100000);
		this.preventVerticalScroll = false;
		// ignore keydown events when any of these elements are focused
		this.blacklist = ["input", "select", "textarea"];        
        
		if (typeof container === "undefined") {
			return;
		}
		this.preventVerticalScroll = preventVerticalScroll;
		// bind events
		this.container = container;
		this.container.HorizontalScroll = this;
		this.wheel = this.wheel.bind(this);
		this.keydown = this.keydown.bind(this);
		this.mouseDown = this.mouseDown.bind(this);
		this.mouseMove = this.mouseMove.bind(this);
		this.mouseUp = this.mouseUp.bind(this);
		this.touchStart = this.touchStart.bind(this);
		this.touchMove = this.touchMove.bind(this);
		this.touchEnd = this.touchEnd.bind(this);
		this.onSpringUpdate = this.onSpringUpdate.bind(this);
		this.onSpringAtRest = this.onSpringAtRest.bind(this);
		this.container.addEventListener("wheel", this.wheel);
		document.addEventListener("keydown", this.keydown);
		document.addEventListener("mousedown", this.mouseDown);
		document.addEventListener("mousemove", this.mouseMove);
		document.addEventListener("mouseup", this.mouseUp);
		document.addEventListener("touchstart", this.touchStart);
		// set up interaction observer
		if (this.container !== document.documentElement) {
			if ("IntersectionObserver" in window) {
				this.observer = new IntersectionObserver(([entry]) => {
					this.containerIsIntersecting = entry.isIntersecting;
				});
				this.observer.observe(this.container);
			}
			else {
				// tslint:disable-next-line:no-console
				console.warn("[horizontal-scroll] WARN: IntersectionObserver not available, assuming key navigation is always applicable to your container.");
			}
		}
		// add CSS to hide scrollbars
		if (!showScrollbars) {
			this.container.classList.add(this.cssClass);
			this.style = document.createElement("style");
			document.head.appendChild(this.style);
			const sheet = this.style.sheet;
			if (sheet) {
				sheet.insertRule(`
                        .${this.cssClass} {
                            overflow-y: hidden;
                            overflow-x: auto;
 
                            /* prevents unwanted gestures and bounce effects */
                            overscroll-behavior: auto;
 
                            /* vendor specific hacks to hide scrollbars */
                            scrollbar-width: none;
                            -ms-overflow-style: none;
                        }
                    `);
				let webkitCss = "::-webkit-scrollbar { display: none; }";
				if (this.container !== document.documentElement) {
					webkitCss = `.${this.cssClass}` + webkitCss;
				}
				sheet.insertRule(webkitCss);
			}
		}
		// init spring
		this.springSystem = new SpringSystem();
		this.spring = this.springSystem.createSpring(40, 20);
		this.spring.setCurrentValue(this.container.scrollLeft);
		this.spring.setOvershootClampingEnabled(true);
		this.spring.setRestSpeedThreshold(30);
		this.spring.setRestDisplacementThreshold(30);
		this.restCallbacks = [];
		this.spring.addListener({onSpringUpdate:this.onSpringUpdate,onSpringAtRest:this.onSpringAtRest});
		this.spring.notifyPositionUpdated();
	}

	wheel(e) {
		if (e.ctrlKey) // Ignore scroll event if ctrl key pressed
			return;
		e.preventDefault();
		const angle = Math.atan2(e.deltaY, e.deltaX) / Math.PI;
		const forward = !(angle < 0.675 && angle > -0.375);
		if (forward) {
			this.scrollLeft();
		} else {
			this.scrollRight();
		}
	}
    
	keydown(e) {
		// only listen to key events if the container actually is in view
		if (this.observer && !this.containerIsIntersecting) {
			return;
		}
		const target = e.target;
		// if any blacklisted elements are focused, we won't handle this keydown.
		if (target &&
            target !== document.body &&
            this.blacklist.includes(target.nodeName.toLowerCase())) {
			return;
		}
		const max = this.container.scrollWidth - this.container.clientWidth;
		let prevent = true;
		switch (e.code) {
		case "Home":
			this.setEndValue(0);
			break;
		case "End":
			this.setEndValue(max);
			break;
		case "ArrowUp":
			this.scrollLeft();
			break;
		case "ArrowDown":
			this.scrollRight();
			break;
		case "ArrowLeft":
			this.scrollLeft();
			break;
		case "ArrowRight":
			this.scrollRight();
			break;
		case "PageUp":
			this.scrollLeft();
			break;
		case "PageDown":
		case "Space":
			this.scrollRight();
			break;
		default:
			prevent = false;
			break;
		}
		if (prevent) {
			e.preventDefault();
		}        
	}
	mouseDown(e){
		//e.preventDefault(); - disabled to allow form field clicks
		this.mouseDownPosition = e;
		this.mouseDownScrollLeft = this.container.scrollLeft;
		this.isMouseDragging = false;
	}
	mouseMove(e){
		e.preventDefault();
		if (this.mouseDownPosition) {
			if (Math.abs(e.screenX - this.mouseDownPosition.screenX)>DRAG_DELTA) {
				this.isMouseDragging = true;
				this.setCurrentValue(this.mouseDownScrollLeft - e.screenX + this.mouseDownPosition.screenX);
			}
		}
	}
	mouseUp(e){
		if (this.isMouseDragging) {
			if (e.screenX > this.mouseDownPosition.screenX) {
				this.scrollLeft();
			} else {
				this.scrollRight();
			}
		}        
		this.mouseDownPosition = null;
		this.isMouseDragging = false;
	}
	touchStart(e){
		this.touchDownPosition = e.touches[0];
		this.touchDownScrollLeft = this.container.scrollLeft;
		this.isTouchDragging = false;
		document.addEventListener("touchmove", this.touchMove);
		document.addEventListener("touchend", this.touchEnd);
	}
	touchMove(e){
		if (Math.abs(e.touches[0].screenX - this.touchDownPosition.screenX)>DRAG_DELTA) {
			this.isTouchDragging = true;
		}
	}
	touchEnd(e){
		if (this.isTouchDragging) {
			this.setCurrentValue(this.container.scrollLeft);
			if (e.changedTouches[0].screenX > this.touchDownPosition.screenX) {
				this.scrollLeft();
			} else {
				this.scrollRight();
			}
		}        
		this.touchDownPosition = null;
		this.isTouchDragging = false;
		document.removeEventListener("touchmove", this.touchMove);
		document.removeEventListener("touchend", this.touchEnd);
	}
	scrollLeft(){
		// find last section that is left of the current scroll position.
		var targetposition = 0;
		for (var i = 0; i < this.container.children.length; i++) {
			let currentChild = this.container.children[i];
			if (currentChild.offsetLeft >= (this.container.scrollLeft - VIEWPORT_MATCH_WIDTH)) break;
			targetposition=currentChild.offsetLeft;
		}
		this.setEndValue(targetposition);
	}
	scrollRight(){
		// find last section that is right of the current scroll position.
		var targetposition = 0;
		for (var i = 0; i < this.container.children.length; i++) {
			let currentChild = this.container.children[i];
			targetposition=currentChild.offsetLeft;
			if ((currentChild.offsetLeft - VIEWPORT_MATCH_WIDTH)> Math.ceil(this.container.scrollLeft)) break;
		}
		this.setEndValue(targetposition);
	}
	scrollTo(el, callback){
		var targetposition=el.offsetLeft;
		this.setEndValue(targetposition);
		if (callback)
			this.restCallbacks.push(callback);
	}
	clampScrollValue(scrollValue) {
		const max = this.container.scrollWidth - this.container.clientWidth;
		scrollValue = Math.max(scrollValue, 0);
		scrollValue = Math.min(scrollValue, max);
		if (this.quantizer) {
			scrollValue = this.quantizer(scrollValue, this.container);
		}
		return scrollValue;
	}
	setCurrentValue(scrollValue) {
		this.spring.setCurrentValue(this.clampScrollValue(scrollValue));
	}
	setEndValue(scrollValue) {
		this.spring.setEndValue(this.clampScrollValue(scrollValue));
	}
	updateScroll(){
		this.spring.setCurrentValue(this.container.scrollLeft);
	}
	onSpringUpdate(currSpring) {
		const value = currSpring.getCurrentValue();
		this.emit("scroll", value);
		// disallow gestures on the vertical axis. also disallow on horizontal when we've scrolled
		this.container.style.overscrollBehaviorY = "none";
		this.container.style.overscrollBehaviorX = value > 0 ? "none" : "auto";
		this.container.scrollLeft = value;
	}
	onSpringAtRest(){
		console.log("spring at rest");
		while (this.restCallbacks.length>0){
			var callback = this.restCallbacks.pop();
			callback();
		}
	}
	destroy() {
		if (typeof this.container === "undefined") {
			return;
		}
		this.container.removeEventListener("wheel", this.wheel);
		document.removeEventListener("keydown", this.keydown);
		document.removeEventListener("mousedown", this.mouseDown);
		document.removeEventListener("mouseup", this.mouseUp);
		document.removeEventListener("mousemove", this.mouseMove);
		document.removeEventListener("touchstart", this.touchStart);
		document.removeEventListener("touchmove", this.touchMove);
		document.removeEventListener("touchend", this.touchEnd);
		if (this.style) {
			this.style.remove();
		}
		this.container.classList.remove(this.cssClass);
		this.spring.destroy();
		this.springSystem.removeAllListeners();
		if (this.observer) {
			this.observer.disconnect();
		}
		delete this.container.HorizontalScroll;
	}
}